import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Layout from '../components/layout';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 100px auto;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;

  h2 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 16px;
    color: #555;
  }

  a {
    color: #007acc;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Articles = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            id
            frontmatter {
              title
              date(formatString: "MMMM DD, YYYY")
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Wrapper>
        <h1>Articles</h1>
        <ul>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Card key={node.id}>
              <h2>
                <Link to={node.frontmatter.slug}>{node.frontmatter.title}</Link>
              </h2>
              <p>{node.frontmatter.date}</p>
            </Card>
          ))}
        </ul>
      </Wrapper>
    </Layout>
  );
};

export default Articles;
